import { GlobalStyles } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import React from 'react';

import { isServer } from '@headway/shared/utils/isServer';

import { ThemeChoice } from './constants';
import { getMuiTheme } from './MuiTheme';
import { legacyTheme as sharedTheme } from './theme';

const THEME_LOCAL_STORAGE_KEY = 'hw-theme';

interface ThemeContextState {
  theme: ThemeChoice;
  setTheme: (theme: ThemeChoice) => void;
}

/** React context for reading and setting the current UI theme. */
export const ThemeContext = React.createContext<ThemeContextState>({
  theme: ThemeChoice.STANDARD,
  setTheme: () => {},
});

export type ThemeProviderProps = {
  /** App-specific overrides to apply to all themes. */
  themeOverrides?: ThemeOptions;
  children: React.ReactNode;
  globalDefault?: boolean;
};

/**
 * Wrapper around MUI's ThemeProvider that also provides a context for reading and setting the
 * current theme. Theme selections are persisted to local storage.
 *
 * This provider should be used only once at the root of the application. If nested elements need to
 * be forced to a particular theme, prefer wrapping them with the base MUI ThemeProvider.
 */
export const ThemeProvider: React.FC<
  React.PropsWithChildren<ThemeProviderProps>
> = ({ children, themeOverrides = {}, globalDefault = true }) => {
  const [themeChoice, setThemeChoice] = React.useState<ThemeChoice>(
    ThemeChoice.STANDARD
  );

  // Updates the theme globally by setting the Material UI internal theme (used by MUI components)
  // and the shared theme (used by non-MUI components). Also presists to local storage.
  const setTheme = (newTheme: ThemeChoice) => {
    setThemeChoice(newTheme);
    sharedTheme.setTheme(newTheme);
    if (!isServer) {
      window.localStorage.setItem(THEME_LOCAL_STORAGE_KEY, newTheme);
    }
  };

  // Wait until component mounts to read local storage in case server-side rendering is being used.
  React.useEffect(() => {
    const storedTheme = window.localStorage.getItem(THEME_LOCAL_STORAGE_KEY) as
      | ThemeChoice
      | '';
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  const muiTheme = createTheme(getMuiTheme(themeChoice), themeOverrides);

  return (
    <>
      {globalDefault && (
        <GlobalStyles
          styles={{
            a:
              themeChoice === ThemeChoice.HIGH_CONTRAST
                ? {
                    color: sharedTheme.highContrastColors.primary,
                    textDecoration: 'underline',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      textDecoration: 'none',
                    },
                    '&:focus': {
                      textDecoration: 'none',
                    },
                  }
                : {
                    color: sharedTheme.defaultColors.primary,
                    textDecoration: 'none',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      textDecoration: 'none',
                    },
                    '&:focus': {
                      textDecoration: 'none',
                    },
                  },
            button: {
              '&:active': {
                outlineOffset: '0',
              },
              outlineOffset: '2px',
            },
            ':root': {
              ...toCSSVars(sharedTheme.color),
            },
          }}
        />
      )}
      <ThemeContext.Provider value={{ theme: themeChoice, setTheme }}>
        <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
      </ThemeContext.Provider>
    </>
  );
};

const kebab = (input: string) => {
  return input
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();
};

function toCSSVars(colors: (typeof sharedTheme)['color']) {
  const css: Record<`--hw-color-${string}`, string> = {};

  for (const [key, value] of Object.entries(colors)) {
    css[`--hw-color-${kebab(key)}`] = value;
  }
  return css;
}
