import styled from '@emotion/styled';
import { SxProps } from '@mui/material';
import React from 'react';

import { theme } from '@headway/helix/theme';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '@headway/shared/utils/userVerificationStatus';
import { A11yModalButton } from '@headway/ui/a11y';
import { MarketConsumer } from '@headway/ui/providers/MarketProvider';
import { SolvvyControllerProvider } from '@headway/ui/Solvvy';

import { useRouter } from '../../hooks/useRouter';
import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import { Footer } from './Footer';
import { Header } from './Header';

const ContentWrapper = styled.main({
  width: '100%',
  minHeight: '70vh', // prevent short content from pulling the Footer up
});

type PageWrapperImplProps = React.ComponentProps<typeof Header> & {
  a11yModalSx?: SxProps;
  AuthStore: IAuthStore;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideA11yModal?: boolean;
  hideZendesk?: boolean;
  hideSolvvyWidget?: boolean;
  className?: string;
  UiStore: IUiStore;
  children: React.ReactNode;
};

function PageWrapperImpl(props: PageWrapperImplProps) {
  const {
    a11yModalSx,
    AuthStore,
    children,
    hideHeader,
    hideFooter,
    hideZendesk,
    hideSolvvyWidget,
    hideA11yModal,
    UiStore,
    className,
    ...rest
  } = props;

  const router = useRouter();

  const user = AuthStore.user;

  const isRegisteredAndVerifiedUser =
    getVerificationStatus(user) ===
    UserVerificationStatus.REGISTERED_AND_VERIFIED;

  const globalCssOptOutByRoute = shouldOptOutOfGlobalCss(router.pathname)
    ? ''
    : 'legacy-global-css';

  return (
    <SolvvyControllerProvider>
      <a
        href="#main-content"
        className="bg-system-green text-system-white z-popover absolute -top-[3em] left-0 p-4 leading-none duration-200 ease-in-out focus:top-0"
      >
        Skip to main content
      </a>
      {!hideHeader && <Header {...rest} />}
      <ContentWrapper
        id="main-content"
        className={(className + ' ' + globalCssOptOutByRoute).trim()}
      >
        {children}
      </ContentWrapper>
      {!hideFooter && (
        <MarketConsumer>
          {({ liveMarkets }) => (
            <Footer userId={user?.id} markets={liveMarkets} />
          )}
        </MarketConsumer>
      )}

      {!hideA11yModal && (
        <A11yModalButton
          sx={{
            bottom: 0,
            height: 46,
            margin: '24px 28px',
            position: 'fixed',
            right: 0,
            width: 46,
            zIndex: theme.layers.base + 1,
            ...a11yModalSx,
          }}
        />
      )}
    </SolvvyControllerProvider>
  );
}

/**
 * A function to determine if a route should be opted out of the global css found in misc.scss.
 * Eventually we want to remove this CSS but we need to do it in a way that doesn't break the app.
 */
const optOutRoutes = new Set(['/account']);

function shouldOptOutOfGlobalCss(pathname: string) {
  return optOutRoutes.has(pathname);
}

export const PageWrapper = withStores(PageWrapperImpl);
