// @ts-nocheck
import { setInteractionModality } from '@react-aria/interactions';
import { ListKeyboardDelegate, useTypeSelect } from '@react-aria/selection';
import {
  chain,
  filterDOMProps,
  useId,
  useLayoutEffect,
  useResizeObserver,
  useSlotId,
} from '@react-aria/utils';
import type { AriaButtonProps } from '@react-types/button';
import type { OverlayTriggerProps } from '@react-types/overlays';
import type {
  AsyncLoadable,
  CollectionBase,
  FocusableProps,
  InputBase,
  LabelableProps,
  MultipleSelection,
  TextInputBase,
  Validation,
} from '@react-types/shared';
import clsx from 'clsx';
import React, {
  FocusEvent,
  HTMLAttributes,
  RefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AriaListBoxOptions,
  FocusRing,
  HiddenSelect,
  mergeProps,
  OverlayContainer,
  useButton,
  useField,
  useFilter,
  useMenuTrigger,
  useOverlayPosition,
} from 'react-aria';
import {
  Item,
  MenuTriggerState,
  Section,
  useMenuTriggerState,
} from 'react-stately';
import { Simplify } from 'type-fest';

import { Chip, ChipGroup } from './Chip';
import { ListBox } from './collections/ListBox';
import { MobilePickerDialog } from './collections/MobilePickerDialog';
import { Popover, POPOVER_MAX_HEIGHT } from './collections/Popover';
import {
  MultiSelectListState,
  useMultiSelectListState,
} from './collections/selection';
import { Tray } from './collections/Tray';
import { filterCollection } from './ComboBox';
import { IconCaretDown } from './icons/CaretDown';
import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { FormInputProps, useFormInput } from './useFormInput';
import { useIsMobileDevice } from './utils';

type SelectProps<T> = Simplify<
  {
    /** Temporary text that occupies the text input when it is empty. */
    placeholder?: string;
    /** Width of the menu when open.
     * @default 'medium'
     */
    menuWidth?: 'small' | 'medium' | 'stretch';

    /** Handler that is called when the selection changes. */
    onSelectionChange?: (keys: Set<string>) => void;

    /** The currently selected keys in the collection (controlled). */
    selectedKeys?: Parameters<typeof useMultiSelectState>['0']['selectedKeys'];
    /** The initial selected keys in the collection (uncontrolled). */
    defaultSelectedKeys?: Parameters<
      typeof useMultiSelectState
    >['0']['defaultSelectedKeys'];

    /** The type of selection that is allowed in the collection. */
    selectionMode: 'single' | 'multiple';

    children?: MultiSelectProps<T>['children'];
    items?: MultiSelectProps<T>['items'];

    autoComplete?: string;
    optionalityText?: React.ReactNode;
    /**
     * Text displayed beneath the input label to provider instructions on how the input should be
     * filled out. This differs from `helpText` in that it is meant to be read by the user prior to
     * filling out the input and should provide precise and actionable instructions. Conversely,
     * `helpText` is meant to be read after the input has been filled out and should provide
     * hints and tips on how to fill out the input.
     *
     * Additionally, `instructionalText` should only be used on block level inputs (i.e. inputs that
     * span the full width of the form) and should not be used on inline inputs (i.e. inputs that
     * are displayed alongside other inputs).
     */
    instructionalText?: React.ReactNode;

    /**
     * Whether to display a search field in the menu on mobile. This is useful when the list of options is
     * very long and the user needs to be able to filter the options. On desktop devices, the list of options
     * is searchable via keyboard input but does not show a search field.
     **/
    searchable?: boolean;
  } & FormInputProps<string>
>;

function Select<T extends object>(props: SelectProps<T>) {
  const { placeholder, menuWidth = 'medium' } = props;

  const { ariaProps, rootProps, hoverProps } = useFormInput(props);

  let isMobileEnabled = true;
  let isMobileDevice = useIsMobileDevice();
  const isMobile = isMobileEnabled && isMobileDevice;

  const state = useMultiSelectState({
    ...ariaProps,
  });

  const rootRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const listboxRef = useRef<HTMLUListElement>(null);

  useAssertFormParentEffect(triggerRef, 'Select', props.name);

  const {
    labelProps,
    triggerProps,
    valueProps,
    menuProps,
    errorMessageProps,
    descriptionProps,
  } = useMultiSelect(
    {
      ...ariaProps,
      disallowEmptySelection: false,
    },
    state,
    triggerRef
  );

  const {
    overlayProps,
    // TODO: Do we want to support consumers controlling the placement of the popover?
    placement,
    updatePosition,
  } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: popoverRef,
    scrollRef: listboxRef,
    placement: 'bottom start',
    offset: 4,
    shouldFlip: true,
    isOpen: state.isOpen && !isMobile,
    onClose: state.close,
    maxHeight: POPOVER_MAX_HEIGHT,
  });

  let [rootWidth, setRootWidth] = React.useState(0);

  let onResizeRoot = React.useCallback(() => {
    const root = rootRef.current;

    if (root) {
      setRootWidth(root.offsetWidth);
    }

    if (state.isOpen) {
      requestAnimationFrame(() => {
        updatePosition();
      });
    }
  }, [state.isOpen, updatePosition]);

  useResizeObserver({
    ref: rootRef,
    onResize: onResizeRoot,
  });

  // Update position once the ListBox has rendered. This ensures that
  // it flips properly when it doesn't fit in the available space.
  useLayoutEffect(() => {
    if (state.isOpen) {
      requestAnimationFrame(() => {
        updatePosition();
      });
    }
  }, [state.isOpen, updatePosition]);

  const { buttonProps } = useButton(triggerProps, triggerRef);
  const optionalityId = useSlotId([Boolean(props.optionalityText)]);
  const instructionalTextId = useSlotId([Boolean(props.instructionalText)]);

  const controlProps = mergeProps(buttonProps, hoverProps, {});
  controlProps['aria-describedby'] =
    [instructionalTextId, optionalityId, controlProps['aria-describedby']]
      .filter(Boolean)
      .join(' ') || undefined;

  const listbox = (
    <ListBox
      listBoxRef={listboxRef}
      {...menuProps}
      state={state}
      disallowEmptySelection={true}
    />
  );

  let overlay;
  if (isMobile) {
    overlay = (
      <OverlayContainer>
        <Tray state={state}>
          <MobilePickerDialog
            state={state}
            title={props.label ?? undefined}
            searchable={props.searchable}
          >
            {listbox}
          </MobilePickerDialog>
        </Tray>
      </OverlayContainer>
    );
  } else {
    overlay = (
      <OverlayContainer>
        <Popover
          __style={{
            ...overlayProps.style,
            width:
              menuWidth === 'stretch' ? rootWidth : menuWidthStyle[menuWidth],
          }}
          popoverRef={popoverRef}
          isOpen={state.isOpen}
          onClose={state.close}
        >
          {listbox}
        </Popover>
      </OverlayContainer>
    );
  }

  const singleSelectState = {
    ...state,
    selectedKey: (Array.from(state.selectedKeys) ?? [])[0],
    selectedItem: (state.selectedItems ?? [])[0],
    setSelectedKey: (k) => state.setSelectedKeys([k]),
  };

  return (
    <div className="hlx-select hlx-select-root" ref={rootRef} {...rootProps}>
      <div className="hlx-select-descriptors">
        <div {...labelProps} className="hlx-select-label">
          {props.label}
        </div>
        {props.optionalityText && (
          <div id={optionalityId} className="hlx-select-optionality-text">
            {props.optionalityText}
          </div>
        )}
        {props.instructionalText && (
          <div
            id={instructionalTextId}
            className="hlx-select-instructional-text"
          >
            {props.instructionalText}
          </div>
        )}
      </div>
      {props.selectionMode === 'single' && (
        <HiddenSelect
          state={singleSelectState}
          triggerRef={triggerRef}
          label={props.label}
          name={props.name}
          autoComplete={props.autoComplete}
          isDisabled={props.disabled}
        />
      )}
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={false}
        autoFocus={props.autoFocus}
      >
        <button
          type="button"
          {...controlProps}
          name={props.name}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          className={clsx('hlx-select-control', {
            placeholder: !state.selectedItems,
          })}
          ref={triggerRef}
        >
          {(state.selectedItems ?? []).length === 0 ? (
            <span {...valueProps}>{placeholder}</span>
          ) : props.selectionMode === 'single' ? (
            <span {...valueProps}>{state.selectedItems[0].rendered}</span>
          ) : props.selectionMode === 'multiple' ? (
            <span {...valueProps} hidden>
              {state.selectedItems.map((item) => item.rendered).join(', ')}
            </span>
          ) : null}

          <IconCaretDown
            className="hlx-select-control-chevron"
            aria-hidden="true"
            size={16}
          />
        </button>
      </FocusRing>
      {props.selectionMode === 'multiple' && state.selectedItems && (
        <ChipGroup
          aria-label="Selected options"
          disabledKeys={
            props.disabled
              ? Array.from(state.collection.getKeys())
              : props.disabledKeys
          }
          onRemove={(removed) => {
            if (props.disabled) {
              return;
            }

            let keys = [];

            if (Set.prototype.difference) {
              keys = state.selectedKeys.difference(removed);
            } else {
              keys = [...state.selectedKeys].filter((k) => {
                return !removed.has(k);
              });
            }
            state.setSelectedKeys(keys);
          }}
          items={state.selectedItems}
        >
          {(item) => (
            <Chip key={item.key} textValue={item.textValue}>
              <span>{item.rendered}</span>
            </Chip>
          )}
        </ChipGroup>
      )}
      {props.helpText && (
        <div className="hlx-select-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-select-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}
      {state.isOpen && overlay}
    </div>
  );
}

type MenuWidth = NonNullable<SelectProps<any>['menuWidth']>;
const menuWidthStyle: Record<MenuWidth, React.CSSProperties['width']> = {
  small: 160,
  medium: 240,
  stretch: '100%',
};

interface MultiSelectAria<T> {
  /** Props for the label element. */
  labelProps: HTMLAttributes<HTMLElement>;

  /** Props for the popup trigger element. */
  triggerProps: AriaButtonProps;

  /** Props for the element representing the selected value. */
  valueProps: HTMLAttributes<HTMLElement>;

  /** Props for the popup. */
  menuProps: AriaListBoxOptions<T>;
}

export function useMultiSelect<T>(
  props: MultiSelectProps<T>,
  state: MultiSelectState<T>,
  ref: RefObject<HTMLElement>
): MultiSelectAria<T> {
  const { disallowEmptySelection, isDisabled } = props;

  const delegate = useMemo(
    () =>
      new ListKeyboardDelegate(
        state.collection,
        state.disabledKeys,
        null as never
      ),
    [state.collection, state.disabledKeys]
  );

  const { menuTriggerProps, menuProps } = useMenuTrigger(
    {
      isDisabled,
      type: 'listbox',
    },
    state,
    ref
  );

  const triggerOnKeyDown = (e: KeyboardEvent) => {
    // Select items when trigger has focus - imitating default `<select>` behaviour.
    // In multi selection mode it does not make sense.
    if (state.selectionMode === 'single') {
      switch (e.key) {
        case 'ArrowLeft': {
          // prevent scrolling containers
          e.preventDefault();

          const key =
            state.selectedKeys.size > 0
              ? delegate.getKeyAbove(state.selectedKeys.values().next().value)
              : delegate.getFirstKey();

          if (key) {
            state.setSelectedKeys([key]);
          }
          break;
        }
        case 'ArrowRight': {
          // prevent scrolling containers
          e.preventDefault();

          const key =
            state.selectedKeys.size > 0
              ? delegate.getKeyBelow(state.selectedKeys.values().next().value)
              : delegate.getFirstKey();

          if (key) {
            state.setSelectedKeys([key]);
          }
          break;
        }

        // no default
      }
    }
  };

  // Typeahead functionality - imitating default `<select>` behaviour.
  const { typeSelectProps } = useTypeSelect({
    keyboardDelegate: delegate,
    selectionManager: state.selectionManager,
    onTypeSelect(key) {
      state.setSelectedKeys([key]);
    },
  });

  const { labelProps, fieldProps, errorMessageProps, descriptionProps } =
    useField({
      ...props,
      labelElementType: 'span',
    });

  typeSelectProps.onKeyDown = typeSelectProps.onKeyDownCapture;
  delete typeSelectProps.onKeyDownCapture;

  const domProps = filterDOMProps(props, { labelable: true });
  const triggerProps = mergeProps(
    typeSelectProps,
    menuTriggerProps,
    fieldProps
  );

  const valueId = useId();

  return {
    errorMessageProps,
    descriptionProps,
    labelProps: {
      ...labelProps,
      onClick: () => {
        if (!props.isDisabled) {
          ref.current?.focus();

          // Show the focus ring so the user knows where focus went
          setInteractionModality('keyboard');
        }
      },
    },
    triggerProps: mergeProps(domProps, {
      ...triggerProps,
      onKeyDown: chain(
        triggerProps.onKeyDown,
        triggerOnKeyDown,
        props.onKeyDown
      ),
      onKeyUp: props.onKeyUp,
      'aria-labelledby': [
        triggerProps['aria-labelledby'],
        triggerProps['aria-label'] && !triggerProps['aria-labelledby']
          ? triggerProps.id
          : null,
        valueId,
      ]
        .filter(Boolean)
        .join(' '),
      onFocus(e: FocusEvent) {
        if (state.isFocused) {
          return;
        }

        if (props.onFocus) {
          props.onFocus(e);
        }

        state.setFocused(true);
      },
      onBlur(e: FocusEvent) {
        if (state.isOpen) {
          return;
        }

        if (props.onBlur) {
          props.onBlur(e);
        }

        state.setFocused(false);
      },
    }),
    valueProps: {
      id: valueId,
    },
    menuProps: {
      ...menuProps,
      disallowEmptySelection,
      autoFocus: state.focusStrategy || true,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      onBlur: (e) => {
        const target = e.relatedTarget;
        if (target && e.currentTarget.contains(target)) {
          return;
        }

        if (props.onBlur) {
          props.onBlur(e);
        }
        state.setFocused(false);
      },
      onFocus: menuProps.onFocus as never,
      'aria-labelledby': [
        fieldProps['aria-labelledby'],
        triggerProps['aria-label'] && !fieldProps['aria-labelledby']
          ? triggerProps.id
          : null,
      ]
        .filter(Boolean)
        .join(' '),
    },
  };
}

export interface MultiSelectProps<T>
  extends CollectionBase<T>,
    AsyncLoadable,
    Omit<InputBase, 'isReadOnly'>,
    Validation,
    LabelableProps,
    TextInputBase,
    MultipleSelection,
    FocusableProps,
    OverlayTriggerProps {
  /**
   * Whether the menu should automatically flip direction when space is limited.
   * @default true
   */
  shouldFlip?: boolean;
}

export interface MultiSelectState<T>
  extends MultiSelectListState<T>,
    MenuTriggerState {
  /** Whether the select is currently focused. */
  isFocused: boolean;

  /** Sets whether the select is focused. */
  setFocused(isFocused: boolean): void;

  inputValue: string;

  setInputValue: (value: string) => void;
}

export function useMultiSelectState<T extends {}>(
  props: MultiSelectProps<T>
): MultiSelectState<T> {
  const [isFocused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const triggerState = useMenuTriggerState(props);
  const listState = useMultiSelectListState({
    ...props,
    onSelectionChange: (keys) => {
      if (props.onSelectionChange != null) {
        if (keys === 'all') {
          // This may change back to "all" once we will implement async loading of additional
          // items and differentiation between "select all" vs. "select visible".
          props.onSelectionChange(new Set(listState.collection.getKeys()));
        } else {
          props.onSelectionChange(keys);
        }
      }

      // Multi select stays open after item selection
      if (props.selectionMode === 'single') {
        triggerState.close();
      }

      setInputValue('');
    },
  });

  const { collection } = listState;
  let { contains } = useFilter({ sensitivity: 'base' });

  let originalCollection = collection;
  let filteredCollection = useMemo(() => {
    if (!inputValue) {
      return collection;
    }

    const filtered = filterCollection(collection, inputValue, contains);
    return filtered;
  }, [collection, inputValue, props.items]);

  return {
    ...listState,
    ...triggerState,
    collection: inputValue ? filteredCollection : originalCollection,
    close() {
      triggerState.close();
    },
    open() {
      // Don't open if the collection is empty.
      if (listState.collection.size !== 0) {
        triggerState.open();
      }
    },
    toggle(focusStrategy) {
      if (listState.collection.size !== 0) {
        triggerState.toggle(focusStrategy);
      }
    },
    isFocused,
    setFocused,
    inputValue,
    setInputValue,
  };
}

export { Select, Item, Section };
