import clsx from 'clsx';
import NextImage from 'next/legacy/image';
import React from 'react';
import { useInRouterContext } from 'react-router-dom';

import { Image as HelixImage } from '@headway/helix/Image';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';

export interface ImageProps {
  src: StaticImageMetadata;
  alt: string;
  sizes?: string;
  className?: string;
}

export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  sizes,
  className,
  ...rest
}) => {
  const isRemix = useInRouterContext();

  if (isRemix) {
    return (
      <div className={clsx('overflow-hidden', className ?? 'w-full')}>
        <HelixImage
          src={src.src}
          width={src.width}
          height={src.height}
          alt={alt}
          sizes={sizes}
          layout="constrained"
        />
      </div>
    );
  }

  return (
    <div className={clsx('overflow-hidden', className ?? 'w-full')} {...rest}>
      <NextImage
        {...(process.env.NEXT_PUBLIC_IMAGE_LOADER_TYPE === 'cloudflare'
          ? {
              loader: transformCloudflareImg,
            }
          : {})}
        src={src}
        alt={alt}
        layout="responsive"
        quality={100}
        sizes={sizes}
      />
    </div>
  );
};
