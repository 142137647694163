import { Card, CardContent } from '@mui/material';
import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { theme as legacyTheme } from '@headway/ui/theme';

export const AddressCardContainer = ({ children }) => (
  <Card
    variant="outlined"
    css={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: legacyTheme.space.base,
      [legacyTheme.media.small]: {
        minWidth: '400px',
      },
    }}
  >
    {children}
  </Card>
);

export const AddressCardContent = ({ children }) => (
  <CardContent
    css={{
      padding: '10px',
      ':last-child': {
        paddingBottom: '10px',
      },
    }}
  >
    {children}
  </CardContent>
);

export const AddressCard = ({
  address,
  toggleAddressArchived,
  toggleIsModalOpen,
  hasActions = true,
  children = null,
}) => {
  return (
    <AddressCardContainer>
      <AddressCardContent>
        <div css={{ ...theme.stack.vertical }}>
          <BodyText>{address.streetLine1}</BodyText>
          {address.streetLine2 && (
            <SubBodyText>{address.streetLine2}</SubBodyText>
          )}
          <SubBodyText>
            {address.city}, {address.state} {address.zipCode}
          </SubBodyText>
          {address.locationInstructions && (
            <SubBodyText>{address.locationInstructions}</SubBodyText>
          )}
        </div>
      </AddressCardContent>
      {hasActions && (
        <AddressCardContent
          css={{
            padding: '10px',
            ':last-child': {
              paddingBottom: '10px',
            },
          }}
        >
          <div css={{ ...theme.stack.horizontal, gap: theme.spacing.x2 }}>
            <Button
              variant="link"
              size="medium"
              onPress={() => {
                return toggleIsModalOpen('Edit', address);
              }}
            >
              Edit
            </Button>
            <Button
              variant="link"
              size="medium"
              onPress={() => {
                return toggleAddressArchived(address);
              }}
            >
              {address.isActive ? 'Archive' : 'Unarchive'}
            </Button>
          </div>
        </AddressCardContent>
      )}
      {children}
    </AddressCardContainer>
  );
};
