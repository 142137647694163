import React from 'react';

import { theme } from '@headway/ui/theme';

import { Image as HeadwayImage } from './Image/Image';

export type QuoteProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'title'
> & {
  photoSrc?: StaticImageMetadata;
  name?: string;
  title: React.ReactNode;
  quote: React.ReactNode;
  photoRadius?: number;
  Image?: React.ComponentType;
};

export const Quote: React.FC<QuoteProps> = ({
  photoSrc,
  name,
  title,
  quote,
  photoRadius,
  Image,
  ...rest
}) => (
  <div
    css={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 756, // the maximum width within the Slider, added to keep consistent outside the Slider
    }}
  >
    <div
      css={{
        position: 'relative',
        background: theme.color.white,
        textAlign: 'left',
        lineHeight: 1.5,
        fontFamily: theme.fontFamily.brandText,
        fontSize: theme.fontSize.xl,
        color: theme.color.darkGray,
        [theme.media.medium]: {
          fontSize: theme.fontSize.xl2,
        },
      }}
    >
      {quote}
    </div>
    <div
      css={{
        background: theme.color.white,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: theme.space.xl3,
      }}
    >
      <div css={{ fontSize: theme.fontSize.xl, textAlign: 'right' }}>
        {name ? (
          <div
            css={{
              color: theme.color.darkGray,
              fontSize: theme.fontSize.base,
              [theme.media.medium]: {
                fontSize: theme.fontSize.lg,
              },
            }}
          >
            {name}
          </div>
        ) : null}
        <div
          css={{
            color: theme.color.gray,
            fontSize: theme.fontSize.base,
            [theme.media.medium]: {
              fontSize: theme.fontSize.lg,
            },
          }}
        >
          {title}
        </div>
      </div>
      {Image ? (
        <Image
          css={{
            width: photoRadius || 60,
            height: photoRadius || 60,
            borderRadius: '50%',
            marginLeft: theme.space.sm,
            flexShrink: 0,
          }}
        />
      ) : photoSrc ? (
        <HeadwayImage
          src={photoSrc}
          alt="Quote photo"
          sizes="96px"
          css={{
            width: photoRadius || 60,
            height: photoRadius || 60,
            borderRadius: '50%',
            marginLeft: theme.space.sm,
            flexShrink: 0,
          }}
        />
      ) : null}
    </div>
  </div>
);
